#main {
  grid-template-rows: max-content max-content max-content max-content max-content max-content 1fr;
  margin-top: 100px;
  padding: 1rem;
  display: grid;
}

body.has-sidebar #main {
  margin-left: 200px;
}

/*# sourceMappingURL=index.4343236f.css.map */
