#main{
    padding: 1rem;   
    display:grid;
    grid-template-rows: max-content max-content max-content max-content max-content max-content 1fr;
    /* grid-row-gap:1rem; */
    
    margin-top:100px;
    /* margin-left:200px; */
}

body.has-sidebar #main{
    margin-left:200px;
}